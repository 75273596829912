import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import tishaPic from "../images/tisha-profile.jpg"

const BookNow = () => (
  <Layout>
    <Seo title="Book Now" />
    <div className="frame_one_column">
      <h1>Book an appointment</h1>
    </div>
    <div className="frame_booking">
      <div className="booking_stylist">
        <a href="https://www.schedulicity.com/scheduling/PASXM5?hl=en-US&gei=mgspZ8GDGsKs0PEPwevhwQ0&rwg_token=AJKvS9VqfSRv7CXPl7gepzlvue1qSqHGB71mHmRjUBtsklrhN57eYCfxWC5I_qptorQOc5xBQ8XY99PdqhNaqPwLWIxOK6_U0g%3D%3D">
          <div className="stylist">
            <h2>Tisha</h2>
            <img className="stylist__pic" src={tishaPic} alt="Tisha" />
          </div>
        </a>
      </div>
    </div>
    <div className="frame_one_column">
      <p></p>
    </div>
  </Layout>
)

export default BookNow
